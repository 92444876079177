import {Toast} from "vant";

export default {
  data () {
    return {
      isLoading: false,
      loading: false,
      finished: false,
      apis:{
        findunique:'/unique/find',
        ellerunique:'/unique/seller/list'
      },
      params:{
        pageIndex:1,
        pageSize:10,
        sortName:'seller_id',
        sortOrder:"asc"
      },
      textState:true,
      businessList:[],//
      count:0,
      content:{}//美食详情
    }
  },
  mounted(){
    const me = this;
    me.$set(me.params,'uniqueId',me.$route.query.uniqueId);
    me.$set(me.params,'commodityId',me.$route.query.commodityId);

    me.foodFunc()
    me.businessFunc();

  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
  methods:{
    onLoad(){//页面加载自调用，滚动检测调动
      const me = this;
      me.params.pageIndex++;
      me.businessFunc()
    },

    onRefresh(){
      setTimeout(() => {
        Toast('刷新成功');
        this.isLoading = false;
        this.params.pageIndex=1;
        this.businessList=[];
        this.finished=false
        this.businessFunc()
      }, 1000);
    },
    routePush(obj){
      console.log(obj);
      let arr = {
        sellerId:obj.sellerId,
        longitude:obj.longitude,
        latitude:obj.latitude
      };
      this.$router.push({path:'/foodDetails',query:{obj:JSON.stringify(arr)}})
    },

    foodFunc () {
      const me = this;
      me.$get({
        url:me.apis.findunique,
        params:{
          uniqueId:me.$route.query.uniqueId
        }
      }).then(rep=>{
        me.content = rep

      })
    },
    businessFunc () {
      const me = this;
      console.log(me.params);
      me.$get({
        url:me.apis.ellerunique,
        params:me.params
      }).then(rep=>{
        if(!rep.data.length){
          this.finished = true;
        }
        me.businessList.push(...rep.data)
        this.loading = false;
      })



    }

  }


}
